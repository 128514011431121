import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";
import Logo from "../../assets/svg/logo.svg";
import midcloud from "../../assets/svg/midcloud.svg";
import leftimage from "../../assets/svg/leftimage.svg";
import rightimage from "../../assets/images/rightimage.png";
import "./Navbar.css";
const Navbar = () => {
  const isSmallScreen = window.innerWidth < 768;
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
      }}
    >
      <div>
        <img src={leftimage} className="left-image" alt="Left" />
      </div>
      <div className="mainheader">
        <img className="mid-image" src={midcloud} alt="Mid" />
        <header className="header">
          <nav className="navbar">
            <div className="logo">
              <img src={Logo} className="logo" alt="logo" />
            </div>

            <ConnectButton
              accountStatus={{ smallScreen: "small", largeScreen: "full" }}
              style={{ fontSize: isSmallScreen ? "12px" : "inherit" }}
            />
          </nav>
        </header>
      </div>
      <div>
        <img className="right-image" style={{ zIndex: "40px" }} src={rightimage} alt="Right" />
      </div>
    </div>
  );
};

export default Navbar;
