import "../App.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import Card from "./Card/Card";
import { ethers } from "ethers";
import Dragon from "../assets/svg/Dragon.png";
import stakingAbi from "../smart_contract/abi/kobito.json";
import tokenAbi from "../smart_contract/abi/tokenAbi.json";
import routerAbi from "../smart_contract/abi/router.json";

import CopySVG from "../assets/svg/copy.svg";
import { useSigner, useProvider, useNetwork } from "wagmi";
import React, { useEffect, useState } from "react";
import { addressSorter, getTimeInDays } from "../helper";
import axios from "axios";
import useCopyToClipboard from "../hooks/useCopyToClipboards";
import { IoMdDoneAll } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader/Loader";
import Navbar from "./Navbar/Navbar";
import GoogleTranslate from "./GoogleTranslate/GoogleTranslate";
import network from "../value.json";

const Staking = () => {
  const notyf = new Notyf({
    duration: 5000,
    position: { x: "right", y: "top" },
    dismissible: true,
  });

  const { data: signer } = useSigner();
  const provider = useProvider();
  const { chain } = useNetwork();
  console.log("chain: ", chain);

  let stakingAddress = "";
  let tokenAddress = "";
  let routerAddress = "";

  if (chain?.name === "Ethereum") {
    stakingAddress = network.ETH.contract;
    tokenAddress = network.ETH.token;
    routerAddress = network.ETH.router;
  } else {
    stakingAddress = network.BSC.contract;
    tokenAddress = network.BSC.token;
    routerAddress = network.BSC.router;
  }

  const staking = new ethers.Contract(
    stakingAddress,
    stakingAbi,
    signer ? signer : provider
  );
  const token = new ethers.Contract(tokenAddress, tokenAbi, signer);
  const router = new ethers.Contract(routerAddress, routerAbi, signer);

  const [copyToClipboard, { value, success }] = useCopyToClipboard();

  let currentURl = window.location.origin;
  const [level, setLevel] = useState("Not Eligible");
  const [amount, setAmount] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [TokenValue, setTokenValue] = useState(0);
  const [referredBy, setReferredBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dailyReward, setDailyReward] = useState("0%");
  const [userAddress, setUserAddress] = useState("");
  const [searchParam, setSearchParams] = useSearchParams();
  const [myTokenBalance, setMyTokenBalance] = useState(0);
  const [userUnlockTime, setUserUnlockTime] = useState(0);
  const [stakingBalance, setStakingBalance] = useState(0);
  const [TotalStakedTokens, setTotalStakedTokens] = useState(0);
  const [TotalTokenValue, setTotalTokenValue] = useState(0);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [stakingLoading, setStakingLoading] = useState(false);
  const [totalClaimedAmount, setTotalClaimedAmount] = useState(0);
  const [referralClaimableRewards, setReferralClaimableRewards] = useState(0);
  const [referralClaimedRewards, setReferralClaimedRewards] = useState(0);

  useEffect(() => {
    if (signer) {
      refreshData();
    }
  }, [signer, decimals]);

  const refreshData = async () => {
    setIsLoading(true);
    await userInfo();
    await getAmount();
    await getTokenValueInUSD();
    setIsLoading(false);
  };

  async function userInfo() {
    try {
      const decimals = await token.decimals();
      const userAddress = await signer.getAddress();
      const tokenbalance = await token.balanceOf(userAddress);
      const stakingBalance = await staking.stakingBalance(userAddress);
      const userUnlockTime = await staking.userUnlockTime(userAddress);
      const userLockTimeConverted = getTimeInDays(Number(userUnlockTime));
      const tokenbalanceConverted = ethers.utils.formatUnits(
        tokenbalance,
        decimals
      );
      const stakingBalanceConverted = ethers.utils.formatUnits(
        stakingBalance,
        decimals
      );
      const referredBy = await staking.referralMapping(userAddress);
      setReferredBy(referredBy);

      setDecimals(decimals);
      setUserAddress(userAddress);
      setUserUnlockTime(userLockTimeConverted);
      setStakingBalance(Number(stakingBalanceConverted).toFixed(2));
      setMyTokenBalance(Number(tokenbalanceConverted).toFixed(2));

      if (stakingBalanceConverted > 10000) {
        setLevel("Advance");
        setDailyReward("1%");
      } else if (stakingBalanceConverted > 5000) {
        setLevel("Intermediate");
        setDailyReward("0.8%");
      } else if (stakingBalanceConverted >= 1000) {
        setLevel("Basic");
        setDailyReward("0.6%");
      } else {
        setLevel("Not Eligible");
        setDailyReward("0%");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async function getAmount() {
    try {
      const decimals = await token.decimals();
      const userAddress = await signer.getAddress();

      const claimableAmount = await staking.claimableRewards(userAddress);
      const claimableAmountConverted = ethers.utils.formatUnits(
        claimableAmount,
        decimals
      );
      const totalClaimableAmount = await staking.totalRewardsClaimed(
        userAddress
      );
      const totalClaimableAmountConverted = ethers.utils.formatUnits(
        totalClaimableAmount,
        decimals
      );
      const claimedAmount = await staking.totalReferralRewardsClaimed(
        userAddress
      );
      const referralClaimedAmountConverted = ethers.utils.formatUnits(
        claimedAmount,
        decimals
      );
      const referralClaimableRewards = await staking.referralClaimableRewards(
        userAddress
      );
      const referralClaimableRewardsConverted = ethers.utils.formatUnits(
        referralClaimableRewards,
        decimals
      );

      setClaimableAmount(Number(claimableAmountConverted).toFixed(2));
      setTotalClaimedAmount(Number(totalClaimableAmountConverted).toFixed(2));
      setReferralClaimedRewards(
        Number(referralClaimedAmountConverted).toFixed(2)
      );
      setReferralClaimableRewards(
        Number(referralClaimableRewardsConverted).toFixed(2)
      );
    } catch (error) {
      console.log("error: ", error);
      console.log();
    }
  }

  async function getTokenValueInUSD() {
    try {
      const decimals = await token.decimals();
      const myStakedBalance = await staking.stakingBalance(userAddress);
      const myStakedBalanceConverted = ethers.utils.formatUnits(
        myStakedBalance,
        decimals
      );
      const totalStakedTokens = await token.balanceOf(stakingAddress);
      const totalStakedTokensConverted = ethers.utils.formatUnits(
        totalStakedTokens.toString(),
        decimals
      );
      const TokenAmount = await router.getAmountsOut(
        ethers.utils.parseUnits("1", decimals),
        [tokenAddress, router.WETH()]
      );
      const TokenAmountConverted = ethers.utils.formatEther(TokenAmount[1]);

      let ethPriceInUSD = 0;
      if (chain?.id === 1) {
        const api =
          "https://api.dexscreener.com/latest/dex/tokens/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
        const response = await axios.get(api);
        ethPriceInUSD = response.data?.pairs[0]?.priceUsd;
      }

      if (chain?.id === 56) {
        const api =
          "https://api.dexscreener.com/latest/dex/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
        const response = await axios.get(api);
        ethPriceInUSD = response.data?.pairs[0]?.priceUsd;
      }

      const tokenPriceInUSD =
        totalStakedTokensConverted * ethPriceInUSD * TokenAmountConverted;
      const myTokenBalanceConvertedIntoUSD =
        myStakedBalanceConverted * ethPriceInUSD * TokenAmountConverted;

      setTotalTokenValue(tokenPriceInUSD.toFixed(2));
      setTotalStakedTokens(Number(totalStakedTokensConverted).toFixed(2));
      setTokenValue(myTokenBalanceConvertedIntoUSD.toFixed(2));
    } catch (error) {
      console.error("Error fetching token price:", error);
    }
  }

  async function checkApproved() {
    try {
      const userAddress = await signer.getAddress();
      const isApproved = await token.allowance(userAddress, stakingAddress);
      if (isApproved.gt(ethers.utils.parseUnits(amount.toString(), decimals))) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      // notyf.error(error.message);
    }
  }

  async function approve() {
    if (await checkApproved()) {
      console.log("Already approved");
      return;
    }
    console.log("Not approved");
    try {
      let _amount = ethers.utils.parseUnits(amount.toString(), decimals);
      let tx = await token.approve(stakingAddress, _amount);
      let reciept = await tx.wait();
      console.log("Approve Tx Receipt: ", reciept);
    } catch (error) {
      console.log(error);
    }
  }

  async function stakeTokens() {
    setStakingLoading(true);
    try {
      if (amount == 0) return notyf.error("Please enter amount to stake");

      await approve();
      const referral = searchParam.get("referral");
      console.log("referral: ", referral);
      let _amount = ethers.utils.parseUnits(amount, decimals);
      let tx = await staking.stake(_amount, referral || userAddress);
      let reciept = await tx.wait();
      console.log("Stake Tx Receipt: ", reciept);
      notyf.success(
        "Staked Successfully and claimable rewards are sent to your wallet address if any"
      );
      await refreshData();
    } catch (err) {
      console.log("stakeTokens", err.message);
      try {
        notyf.error(err.error.data.message);
      } catch {
        notyf.error("Something went wrong, please try again!");
      }
    } finally {
      setStakingLoading(false);
    }
  }

  async function claimRewardsByReferral() {
    try {
      setStakingLoading(true);
      let tx = await staking.claimReferralRewards();
      let reciept = await tx.wait();
      notyf.success("Referral Rewards Claimed Successfully");
      console.log("ClaimToken: ", reciept);
      await refreshData();
    } catch (error) {
      console.log(error);
      try {
        notyf.error(error.error.data.message);
      } catch {
        notyf.error("Something went wrong, please try again!");
      }
    } finally {
      setStakingLoading(false);
    }
  }

  async function claimRewards() {
    try {
      setStakingLoading(true);
      let tx = await staking.claimRewards();
      let reciept = await tx.wait();
      console.log("Claim Rewards Tx Receipt: ", reciept);
      await refreshData();
    } catch (error) {
      console.log("eeoeoeo", error.toString());
      try {
        notyf.error(error.error.data.message);
      } catch {
        notyf.error("Something went wrong, please try again!");
      }
    } finally {
      setStakingLoading(false);
    }
  }

  // if (isLoading) return <p>...Loading</p>

  return (
    <>
      {isLoading || (stakingLoading && <Loader />)}
      <Navbar />

      <div className="content-container">
        <GoogleTranslate />
        <section className="staking" style={{ paddingLeft: "2rem" }}>
          <div className="info">
            <h1 className="infoHeading">FET Mining Rigs</h1>
            <p className="infoPara">
              By Mining Rigs Fetch.Ai, you can earn generous daily rewards!
              Depending on your investment choices, you can not only enjoy daily
              dividends but also have the opportunity to earn substantial
              referral bonuses. Upgrade and invest to increase your income and
              equity. Start mining FET tokens today!
            </p>
          </div>
          <div className="detail-card-container">
            <Card
              num={TotalTokenValue}
              price={true}
              text={"Total Value Locked"}
            />
            <Card num={level} text={"Your Level"} />
            <Card num={TotalStakedTokens} text={"Total Token Locked"} />
          </div>

          <div className="container" style={{ maxWidth: "1700px" }}>
            <div className="left card">
              <div className="token-value">
                <h3>${TokenValue}</h3>
                <span>Token Value</span>
              </div>

              <div className="day-bar orange-bg">365 days / 1 Year</div>

              <div className="bal-info">
                <div className="bal-left">
                  <p>
                    My Balance :{" "}
                    <span className="orange-text">{myTokenBalance}</span>{" "}
                  </p>
                  <p>
                    My Deposit Balance :{" "}
                    <span className="orange-text">{stakingBalance}</span>{" "}
                  </p>
                  <p>
                    Lock Deadline :{" "}
                    <span className="orange-text">{userUnlockTime}</span>{" "}
                  </p>
                </div>
                <div className="bal-right">
                  <p>Daily Reward</p>
                  <h2 className="orange-text">{dailyReward}</h2>
                </div>
              </div>
              <div className="hr"></div>
              <div className="user-input">
                <div className="input-box">
                  <input
                    type="number"
                    className="tokeninput"
                    name="tokenAmount"
                    value={amount}
                    placeholder="0.00"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <button
                    className="orange-bg btn"
                    onClick={() => setAmount(myTokenBalance)}
                  >
                    Max
                  </button>
                </div>
                <div className="btn-group">
                  <button className="btn orange-bg" onClick={stakeTokens}>
                    Deposit
                  </button>
                </div>
              </div>
            </div>
            <div className="right card">
              <div className="details">
                <h2>
                  {totalClaimedAmount} <span translate="no">FET</span>
                </h2>
                <p className="orange-text">Total Reward</p>
              </div>
              <div className="hr"></div>
              <div className="details r-btn">
                <div>
                  <h2>
                    {claimableAmount} <span translate="no">FET</span>
                  </h2>
                  <p className="orange-text">Daily Reward</p>
                </div>
                <button className="btn orange-bg" onClick={claimRewards}>
                  Claim
                </button>
              </div>
              <div className="hr"></div>
              <div className="details">
                <h2>{addressSorter(referredBy)}</h2>
                <p className="orange-text">Referrer</p>
              </div>
              <div className="hr"></div>
              <div className="details r-btn">
                <div>
                  <h2>
                    {referralClaimedRewards} <span translate="no">FET</span>
                  </h2>
                  <p className="orange-text">Earning By Referral</p>
                </div>
                <button
                  className="btn orange-bg"
                  onClick={claimRewardsByReferral}
                >
                  Claim{" "}
                  {referralClaimableRewards > 0 ? referralClaimableRewards : ""}
                </button>
              </div>
              <div className="hr"></div>
              <div className="referral">
                <div className="referral-heading">
                  <h3>Referral Link</h3>
                  <img className="copy-svg" src={CopySVG} alt="" />
                </div>
                <div
                  className="referral-link"
                  onClick={() =>
                    copyToClipboard(`${currentURl}/?referral=${userAddress}`)
                  }
                >
                  <p>
                    {addressSorter(currentURl)}/?referral=
                    {addressSorter(userAddress)}
                  </p>
                  {success ? (
                    <>
                      <span className="orange-text">
                        <IoMdDoneAll />
                      </span>
                    </>
                  ) : (
                    <img className="copy-svg" src={CopySVG} alt="" />
                  )}
                </div>
              </div>
            </div>
            <img
              style={{ position: "absolute", right: "-146.7px", top: "-14rem" }}
              src={Dragon}
              alt="Dragon"
              className="dragon-image"
            />
          </div>

          <div className="referrals-details">
            <div className="referral-row">
              <p>FET Mining Rigs = 1000-5000</p>
              <p>Earn 0.6% Daily</p>
              <p className="orange-text">Junior</p>
            </div>
            <div className="referral-row">
              <p>FET Mining Rigs = 5001-10000</p>
              <p>Earn 0.8% Daily</p>
              <p className="orange-text">Intermediate</p>
            </div>
            <div className="referral-row">
              <p>FET Mining Rigs = 10001 - 20000</p>
              <p>Earn 1% Daily</p>
              <p className="orange-text">Advanced</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Staking;
