// GoogleTranslate.js

import React, { useEffect } from "react";

function GoogleTranslate() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    return () => {
      document.body.removeChild(addScript);
    };
  }, []);

  return (
    <div id="google_translate_element" style={{ paddingLeft: "2rem" }}></div>
  );
}

export default GoogleTranslate;
