import React from 'react'
import './Loader.css'

const Loader = () => {
    return (
        <div className="overlay-loader">
            <div className="overlayLoading">
                <div className="spinner"></div>
            </div>
        </div>
    )
}

export default Loader