import "./App.css";
import "notyf/notyf.min.css";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, polygon, bsc } from "wagmi/chains";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";
import {
  getDefaultWallets,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS file
import { BrowserRouter } from "react-router-dom";
import Staking from "./components/Staking";

// const projectId = process.env.REACT_APP_PROJECT_ID;
const projectId = "a24eb7400f3441a0ac702f9974be33a2";

const { chains, provider } = configureChains([bsc], [publicProvider()]);

const { wallets } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId,
  chains,
});

const connectors = connectorsForWallets([...wallets]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
      startEvent: "scroll",
    });
  }, []);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          initialChain={56}
          chains={chains}
          theme={darkTheme({
            accentColor:
              "linear-gradient(125deg, #ED0137 20.07%, #F05733 75.59%)",
            accentColorForeground: "white",
            overlayBlur: "small",
            borderRadius: "medium",
          })}
        >
          <BrowserRouter>
            <main className="main">
              <section className="main-wrapper">
                <Staking />
              </section>
            </main>
          </BrowserRouter>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
