export function getTimeInDays(targetTimeInSeconds) {
    if (targetTimeInSeconds === 0) return '--'
    const date = new Date(targetTimeInSeconds * 1000);

    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

}


export function addressSorter(address) {
    const start = address.slice(0, 8)
    const end = address.slice(-4);
    return `${start}...${end}`
}