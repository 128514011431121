import React from 'react'
import './Card.css'
const Card = ({ num, text, price }) => {
    return (
        <div className='detail-card'>
            <h2>{price ? "$" : ""}{num}</h2>
            <p className='orange-text bold'>{text}</p>
        </div>
    )
}

export default Card